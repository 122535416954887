@import './src/assets/scss/variables';

.ResetPassword {
  margin-top: 5rem;
  font-family: 'Roboto', 'Open Sans', sans-serif;
  color: $text-color;

  & h1 {
    margin-bottom: 7rem;
  }

  & .PasswordReset-form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    align-items: center;

    & div {
      width: 30%;
    }

    & .formInput-container {
      display: grid;
      grid-template-columns: 40% 40% 5%;
      justify-content: start;
      align-items: center;
      margin-bottom: 1.5rem;

      & svg {
        margin-left: 1rem;
        justify-self: center;
      }
    }

    & .message {
      height: 2rem;
      margin-bottom: 1rem;
      text-align: center;
      font-size: x-large;
      font-weight: bolder;
    }

    & .error {
      color: $button-danger;
    }

    & .success {
      color: $sheet-title;
    }
  }


  & .resetPassword_buttons {
    text-align: center;
    margin: 3rem 1rem;


  }
}