@import '../../../../src/assets/scss/variables';

.AddDocumentModal {
	& .modal-content {
		& .modal-header {
			display: grid;
			grid-template-columns: 90% 3%;
			justify-items: center;
		}

		& .modal-body {
			& form {
				display: grid;
				grid-template-columns: 50% 50%;
				grid-row-gap: 30px;

				& .Message {
					grid-area: 1/1/1/3;
					justify-self: center;
					color: red;
					font-size: large;
				}

				& .form-group {
					display: grid;
					grid-row: 2;
					grid-template-columns: 15% 35% 5% 5%;
					align-content: center;
					justify-content: start;
					align-items: center;
					justify-items: start;

					& input {
						padding-left: 0.5rem;
					}

					& svg {
						align-self: self-start;
					}

					& #amount {
						width: 95%;
					}
				}

				& .upload-infos {
					grid-area: 1/1/1/4;
					justify-self: center;
					margin-top: 2rem;
				}

				& .files-list {
					justify-self: center;
					width: 50%;
					row-gap: 0.5rem;
					margin-bottom: 2rem;

					& .file-item {
						display: flex;
						align-items: center;
						justify-content: center;

						& .file {
							background-color: #fff;
							border-radius: 7px;
							padding: 0.5rem 1rem;
							height: 82%;
							display: flex;
							flex-wrap: nowrap;
							align-content: center;
							align-items: center;
							justify-content: flex-end;
						}
					}
				}

				& .button {
					grid-area: 3/1/3/4;
					justify-self: center;

					& svg {
						margin-right: 1rem;
					}
				}

				& .advertising,
				.error-message {
					//grid-row: 3;
					grid-area: 3/1/3/4;
					justify-self: center;
					color: $button-danger;
				}

				& .error-message {
					grid-area: 4/1/4/4;
				}

				& .editButtons {
					grid-area: 5/1/5/4;
					justify-self: center;
					margin-bottom: 2rem;
					width: 50%;
				}
			}
			& ul {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				& li {
					& a {
						margin-left: 1rem;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.AddDocumentModal {
		& .modal-content {
			& .modal-body {
				& form {
					display: grid;
					grid-template-columns: 50% 50%;
					grid-row-gap: 30px;

					& .upload-infos {
						grid-area: 1/1/1/4;
						justify-self: center;
						margin-top: 2rem;
					}

					& .files-list {
						grid-area: 2/1/2/4;
						justify-self: center;
						width: 30%;

						& .file-item {
							display: grid;
							grid-template-columns: 80% 15%;
							align-items: center;
							align-content: center;
							justify-content: center;

							& .file {
								background-color: #fff;
								border-radius: 7px;
								padding-right: 0.5rem;
								height: 82%;
								display: flex;
								flex-wrap: nowrap;
								align-content: center;
								align-items: center;
								justify-content: flex-end;
							}
						}
					}

					& .button {
						grid-area: 3/1/3/4;
						justify-self: center;
					}

					& .advertising,
					.error-message {
						//grid-area: 2/1/2/4;
						justify-self: center;
						color: $button-danger;
					}

					& .error-message {
						grid-area: 4/1/4/4;
					}

					& .editButtons {
						grid-area: 5/1/5/4;
						justify-self: center;
						margin-bottom: 2rem;
						width: 50%;
					}
				}
			}
		}
	}
}
