@import '/src/assets/scss/variables';

.duplicate-sub {
	& .modal-content {
		& .modal-body {
			& .DuplicateSubventionModal > form {
				display: flex !important;
				flex-wrap: wrap !important;
				gap: 2rem !important;

				& .section-grid {
					width: 100%;
					display: flex;
					flex-direction: row;
					gap: 2rem;
				}

				& .section-buttons {
					justify-content: center;
					align-items: center;
				}

				& .form-group {
					width: 50%;
					display: grid;
					grid-template-columns: 75% 25%;
					grid-template-rows: 50% 50%;

					& label {
						grid-area: 1/1/1/1;
					}

					& div,
					select,
					input {
						grid-area: 2/1/2/1;
					}

					& .asterisk {
						grid-area: 2/2/2/2;
						margin-left: 0.5rem;
					}
				}

				& .form-duration {
					grid-area: 6/1/6/1;
					display: flex;
					width: 40%;
					justify-content: flex-end;
					align-items: center;
					flex-wrap: wrap;

					& label {
						width: 100%;
					}

					& .duration-time {
						width: fit-content;
						margin-right: 0.5rem;
					}

					& .duration-type {
						width: fit-content;
					}
				}

				& .form-message {
					grid-area: 6/1/6/3;
					color: $alert1;
					margin: 0 auto;
					margin-bottom: 1rem;
				}

				& .buttons {
					grid-area: 7/1/7/3;
					width: 50%;
					justify-self: center;
					align-self: center;
				}
			}
		}
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.duplicate-sub {
	}
}

@media screen and (max-height: $screen-lg-min) {
	.duplicate-sub {
		& .modal-content {
			& .modal-body {
				padding: 0 1rem;

				& form {
					margin: 0.5rem 2rem;
				}
			}
		}
	}
}

@media screen and (min-height: $screen-xl-min) {
	.duplicate-sub {
	}
}

@media screen and (max-width: $screen-lg-min) {
	.duplicate-sub {
		& .modal-content {
			& .modal-body > .DuplicateSubventionModal > form {
				font-size: small;
			}
		}
	}
}
