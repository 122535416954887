@import '../../assets/scss/variables';

.ProjectUser {
	.project__label {
		display: flex;
		margin-bottom: 1rem;
		gap: 2rem;
	}

	& table {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;
		padding: 0 2rem;

		& thead {
			background-color: $table-head;
			padding: 0.5rem;
		}
	}

	& .UserCard {
		display: grid;
		grid-template-columns: 25% 25% 25% 20% 2%;

		& th {
			display: flex;
			align-items: center;
			padding: 0 0.5rem;

			& input {
				border: 2px solid $text-color;
				width: 50%;
				margin-right: 10px;
			}

			& svg {
				margin-right: 0;
				width: 20px;
			}
		}

		& .user_title {
			color: #fff;
			& tr {
				& th {
					height: 2rem;
				}
			}
		}

		& .user-card__delete {
			padding: 0;
		}
	}

	& .row_gap {
		margin-top: 0.5rem;
	}
}

@media screen and (max-width: $screen-lg-min) {
	.ProjectUser {
		& .UserCard {
			& th {
				font-size: small;
			}

			& .user_title {
				font-size: medium;
			}
		}
	}
}
