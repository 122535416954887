@import '../../assets/scss/variables';

.ProjectGeneralInfos {
	& .project-general-infos {
		display: grid;
		grid-template-columns: 30% 30% 30%;
		grid-row-gap: 25px;

		& input,
		select {
			padding: 0 0.5rem;
			border: 1px solid $detail-text-color-grey;
		}

		& .project-code {
			grid-area: 1/1/1/1;
		}

		& .project-name {
			grid-area: 2/1/2/1;
		}

		& .project-etat {
			grid-area: 1/2/1/2;
		}

		& .project-start-date {
			grid-area: 1/3/1/3;
		}

		& .project-end-date {
			grid-area: 2/3/2/3;
		}

		& .react-datepicker-wrapper {
			width: auto;

			& .react-datepicker__input-container {
				width: 80%;

				& input {
					width: 96%;
				}
			}
		}

		& span,
		input,
		select {
			margin-left: 0.5rem;
		}

		& .project-manager {
			grid-area: 3/1/3/4;

			& h3 {
				margin-bottom: 0.5em;
			}

			& .manager {
				display: grid;
				grid-template-columns: 35% 35%;
				row-gap: 10px;

				& .manager-infos {
					display: flex;
					flex-direction: row;
					align-items: center;
				}

				& .manager-email > input {
					width: 80%;
				}
			}
		}

		& .project-description {
			grid-area: 4/1/4/4;

			& textarea {
				border: 1px solid $detail-text-color-grey;
				width: 100%;
				height: auto;
				margin-top: 1em;
				padding: 10px;
			}
		}
	}

	& .editButtons {
		justify-self: center;
		margin: 0 auto 2rem auto;
		width: 50%;
	}
}

@media screen and (max-width: $screen-xl-min) {
	.ProjectGeneralInfos {
		& .project-general-infos {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-row-gap: 25px;

			& .project-code {
				grid-area: 1/1/1/1;
			}

			& .project-name {
				grid-area: 2/1/2/1;

				& input {
					width: 90%;
				}
			}

			& .project-etat {
				grid-area: 3/1/3/1;
			}

			& .project-start-date {
				grid-area: 1/2/1/2;
			}

			& .project-end-date {
				grid-area: 2/2/2/2;
			}

			& .project-elem {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				& label {
					margin-bottom: 1rem;
				}
			}

			& .project-manager {
				grid-area: 4/1/4/3;
			}

			& .project-description {
				grid-area: 5/1/5/3;

				& textarea {
					border: 1px solid $detail-text-color-grey;
					width: 100%;
					height: auto;
					margin-top: 1em;
				}
			}

			& .project-manager {
				& h3 {
				}

				& .manager {
					grid-template-columns: 50% 50%;
					width: 100%;

					& .manager-infos {
						display: flex;
						flex-direction: row;
						align-items: center;
					}

					& .manager-email > input {
						width: 80%;
					}
				}
			}
		}
	}
}
