@import '../../assets/scss/variables';

.Buttons {
	display: flex;
	justify-content: space-evenly;
	& button {
		height: 3rem;
	}

	& .cancel {
		background: $text-color;
	}
}
