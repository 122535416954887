@import "./src/assets/scss/variables";

.SearchBarBack {
  & input {
    width: 60%;
    padding-left: 0.5rem;
  }

  & svg {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: $screen-xxl-min) {
  .SearchBarBack {
    width: 18em;

    & input.search-input {
      margin-right: 1em;
    }
  }
}
