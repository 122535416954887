@import '../../assets/scss/variables';

.ProjectDetail {
	font-family: 'Roboto', 'Open Sans', sans-serif;
	overflow-y: auto;
	max-height: 67vh;
	min-height: 6vh;

	& .return-button {
		margin-left: 3rem;
		margin-bottom: 5rem;
		font-size: large;
	}

	& svg {
		justify-self: end;
		margin-right: 1rem;
		color: $button-color;
	}

	/*
      & input,
      select {
          padding: 0 0.5rem;
          border: 1px solid $detail-text-color-grey;
      }
  */
	& section {
		color: $detail-text-color;
		font-size: large;
		font-weight: bolder;
		background-color: #fff;
		width: 95%;
		margin: 1rem auto;
		padding: 1rem;

		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}

	& .loading {
		display: flex;
		flex-direction: row;
		align-content: center;
		justify-content: center;
		align-items: center;
		color: $Page-title;

		& .spinner-text {
			margin-left: 0.5em;
		}
	}
}

@media screen and (max-width: $screen-xl-min) {
	.ProjectDetail {
		max-height: 72vh;
	}
}
