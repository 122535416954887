@import './src/assets/scss/variables';

.Contact {
  height: 100vh;
  padding-top: 8rem;
  overflow: auto;

  @media screen and (max-height: 667px), screen and (max-width: 768px) {
    padding-top: 1rem;
  }

  & .logo {
    position: fixed;
    top: 0;
    left: 0;

    & img {

      width: 100px;
      height: 70px;
    }

    @media screen and (max-height: 667px), screen and (max-width: 768px) {
      position: inherit;
      text-align: center;
    }
  }

  .contact {

    &__header {
      display: flex;
      justify-content: flex-end;

      & button {
        padding: 0.75em;
        margin: 2em;
        height: auto;
      }
    }

    &__title {
      width: 100%;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 60%;
      margin: auto;

      @media screen and (max-height: 1024px) {
        width: 90%;
      }

      @media screen and (max-height: 768px) {
        width: 100%;
      }

      &__form {
        display: flex;
        flex-direction: column;

        &__body {
          display: flex;
          flex-direction: column;
        }

        &__inputs {
          display: flex;
            flex-direction: column;
        }

        &__title {
          text-align: center;
          margin: 2em;
        }
        &__label {
          margin: 0.5em;
        }
        &__input {
          margin: 0.5em;
          padding: .5rem;
        }
        &__textarea {
          height: auto;
          resize: none;
          margin: 0.5em;
          padding: .5rem;

        }
        &__button {
          margin: auto;
        }

        &__errors {
          text-align: center;
          font-size: xx-large;
          color: $alert1;
        }

        &__success {
          text-align: center;
          font-size: xx-large;
          color: $text-color;
        }
      }

      &__coordinates {

        &__title {
          text-align: center;
          margin: 2em;
        }
        &__item {
          margin: 1em;
          &__title {
            margin-bottom: 1em;
            font-weight: bold;
          }
        }
      }

    }

  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 1rem;
    font-size: larger;
    font-weight: bold;

    @media screen and (max-height: 667px), screen and (max-width: 768px) {
      position: inherit;
      transform: none;
      text-align: center;
    }
  }
}

