@import './src/assets/scss/variables';

.SubventionReminder {
	& .sub-reminder-infos {
		display: grid;
		grid-template-columns: 15% 30% 10% 10% 20%;
		grid-template-rows: 30% 30% 30%;
		align-items: center;
		justify-items: start;
		padding: 1rem 1rem 2rem 1rem;
		grid-row-gap: 10px;

		& input {
			padding-left: 0.5rem;
		}

		& .reminder-item-delais {
			grid-area: 1/2/1/2;
			justify-self: center;
		}

		& .reminder-item-rappel {
			grid-area: 1/4/1/4;
			justify-self: center;
		}

		& .reminder-item-expense {
			grid-area: 2/1/2/1;
		}

		& .reminder-item-expense_receipts_date {
			grid-area: 2/2/2/2;
			justify-self: center;
		}

		& #expense_receipts-date {
			grid-area: 2/2/2/2;
			width: fit-content;
			justify-self: center;
		}

		& .reminder_expense-item_limit {
			grid-area: 2/3/2/3;
			justify-self: end;
			width: 3rem;
			text-align: center;
		}

		& .reminder_item-expense_type {
			grid-area: 2/4/2/4;
			justify-self: center;
		}

		& .expense-text {
			grid-area: 2/5/2/5;
		}

		& .reminder-item-balance {
			grid-area: 3/1/3/1;
		}

		& #balance_submission-date {
			grid-area: 3/2/3/2;
			width: fit-content;
			justify-self: center;
		}

		& .reminder-balance-item-limit {
			grid-area: 3/3/3/3;
			justify-self: end;
			width: 3rem;
			text-align: center;
		}

		& .reminder-item-balance-type {
			grid-area: 3/4/3/4;
			justify-self: center;
		}

		& .balance-text {
			grid-area: 3/5/3/5;
		}
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.SubventionReminder {
		& .sub-reminder-infos {
			grid-template-columns: 220px 30% 11% 18% 14%;
		}
	}
}
