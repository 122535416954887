@import '../../assets/scss/variables';

.AddUserModal,
.UserDetailModal {
	& div.modal-content {
		& div.modal-body {
			& form {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				& .lined {
					height: 0;
				}

				& .collapsible-content {
					display: flex;
					gap: 1rem;
				}
			}
		}
	}
}
