@import '../../../assets/scss/variables';

.add-sub {
	& .modal-content {
		font-size: x-large;
		max-height: 90vh;
		overflow-y: auto;

		& .form-select {
			padding-right: 2rem;
		}

		& svg {
			align-self: flex-start;
			margin-left: 0.5rem;
		}

		& button.btn-close {
			margin: 0;
		}

		& .modal-body > form.CreateSubventionModal {
			display: flex;
			flex-wrap: wrap;
			gap: 2rem;

			& .section-grid {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 2rem;
			}

			& .section-buttons {
				justify-content: center;
				align-items: center;
			}

			& .form-group {
				width: 50%;
				display: grid;
				grid-template-columns: 75% 25%;
				grid-template-rows: 50% 50%;

				& label {
					grid-area: 1/1/1/1;
				}

				& div,
				select,
				input {
					grid-area: 2/1/2/1;
				}

				& .asterisk {
					grid-area: 2/2/2/2;
					margin-left: 0.5rem;
				}
			}

			& .form-description {
				width: 100%;
				height: auto;

				& textarea {
					flex: 1 1;
					line-height: 1.5;
					height: auto;
					width: 90%;
				}
			}

			& .form-dates {
				display: flex;
				flex-direction: row;
				gap: 2rem;
				width: 100%;

				& .form-start-date,
				.form-end-date {
					width: 45%;
				}
			}

			& .form-duration {
				grid-area: 6/1/6/1;
				display: flex;
				width: 40%;
				justify-content: flex-end;
				align-items: center;
				flex-wrap: wrap;

				& label {
					width: 100%;
				}

				& .duration-time {
					width: fit-content;
					margin-right: 0.5rem;
				}

				& .duration-type {
					width: fit-content;
				}
			}

			//& .duration-text {
			//	display: flex;
			//	gap: 1rem;
			//
			//	& .Message {
			//		grid-area: 6/1/6/3;
			//		color: $alert1;
			//		margin: 0 auto;
			//		margin-bottom: 1rem;
			//	}
			//}

			& .form-message {
				grid-area: 6/1/6/3;
				color: $alert1;
				margin: 0 auto;
				margin-bottom: 1rem;
			}

			& .buttons {
				grid-area: 7/1/7/3;
				width: 50%;
				justify-self: center;
				align-self: center;
			}
		}
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.add-sub {
		& .modal-content {
			& .modal-body > form {
				display: flex;
				flex-wrap: wrap;

				& .form-group {
					width: 45%;
					display: flex;
					flex-direction: column;
				}

				& .form-description {
					width: 100%;
				}

				& .form-dates {
					display: flex;
					flex-direction: row;
					gap: 2rem;
					width: 100%;

					& .form-start-date,
					.form-end-date {
						width: 45%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $screen-lg-min) {
	.add-sub {
		& .modal-content {
			& .modal-body > form {
				display: flex;
				flex-wrap: wrap;

				& .form-group {
					width: 100%;
				}

				& .form-dates {
					display: flex;
					flex-direction: column;
					gap: 2rem;
					width: 100%;

					& .form-start-date,
					.form-end-date {
						width: 100%;
					}
				}
			}
		}
	}
}

@media screen and (max-height: $screen-lg-min) {
	.add-sub {
		max-width: 1000px;
	}
}

@media screen and (min-height: $screen-xl-min) {
}

@media screen and (max-width: $screen-lg-min) {
	.add-sub {
		& .modal-content {
			& .modal-body > form.CreateSubventionModal {
				font-size: small;
			}
		}
	}
}
