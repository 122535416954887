@import '../../../../../../assets/scss/variables';

.AccountAddProductModal {

  & .modal-content {

    & .modal-body {

      & form {

        & .form-group.switch-content {
          margin: 1rem auto 2rem auto;

          & label {
            width: auto;
            margin-right: 1rem;
          }

          & .react-switch-bg {
            padding: 0 .5rem;
            & div {
              width: 100% !important;
            }
          }

          & .switch-item {
            height: 100%;
            display: flex;
            align-content: center;
            align-items: center;
            padding: 0 .2rem;
            justify-content: center;
          }

          & .switch-inactive {
            color: #fff;
          }

          & .switch-active {
            color: #fff;
          }
        }

        & .form-group {
          width: 80%;
          margin: 0 auto;

          & .input-requierement {
            display: flex;
            & input {
              margin-right: 0.5rem;
              margin-bottom: 1rem;
            }
          }
        }

        & .form-message {
          color: $alert1;
          text-align: center;
          font-size: large;
          font-weight: bold;
          margin-bottom: 1rem;
        }
      }
    }
  }

}