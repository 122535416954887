@import './src/assets/scss/variables';

.RecieverDetail {
  display: grid;
  grid-template-columns: 29% 36.8% 30% 3%;
  justify-items: center;
  align-items: center;
  font-size: larger;
  padding: .5rem 0;
  border: 1px solid $text-color;
  height: 3rem;
  position: relative;
  color: #000;


}

.recieversList-title {
  font-size: 1.6rem;
  font-weight: bold;
  height: 4rem;
  background-color: $sheet-title;
  color: #FFFFFF;
}

div.mb-3 > .form-select, .form-control {
  line-height: inherit;
}

.inactive {
  color: $text-color;
  font-style: italic;
}


@media screen and (max-width: $screen-xxl-min) {

  .RecieverDetail {
    font-size: medium;
  }

  .recieversList-title {
    font-size: larger;
    height: 3rem;
  }
}

@media screen and (max-width: $screen-xl-min) {
  .RecieverDetail {
    font-size: small;
  }

  .recieversList-title {
    font-size: medium;
  }
}

@media screen and (max-width: $screen-lg2-min) {
  .RecieverDetail {
    font-size: xx-small;
  }

  .recieversList-title {
    font-size: xx-small;
  }
}