@import '../../assets/scss/variables';

.ProjectsList {
	font-family: 'Roboto', 'Open Sans', sans-serif;
	padding: 1rem;

	& .projects-container {
		width: 99.9%;

		& .projectsList-title {
			margin: 1rem;
			padding-left: 0;
			min-width: 1025px;
			background: $table-head;
			color: #fff;
			font-size: large;

			& .project-name {
				justify-content: flex-start;
				padding-left: 0.5rem;
			}

			& .project-sub {
				flex-direction: column;
				align-items: flex-start;

				& .sub-item {
					width: 100%;
					height: 2rem;
					padding-left: 0.5rem;
					display: flex;
					flex-wrap: nowrap;
					align-items: center;
					border: solid 1px $background-color;
				}
			}

			& .title {
				height: 3rem;

				width: 100%;
				display: grid;
				grid-template-columns: 85% 15%;
				align-content: center;
				justify-content: center;
				align-items: center;
				justify-items: center;
				padding-left: 0.5rem;
			}

			& .projectList-title {
				height: 3rem;
			}
		}

		& .projects-list {
			background-color: #fff;
			color: $text-color;
		}

		& .project-item-content {
			border: solid 1px $background-color;
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction: row;
			flex-wrap: nowrap;
			align-content: center;
			justify-content: center;
			align-items: center;
		}

		& .text-wrapper {
			padding: 0 0.5rem;
			overflow-wrap: anywhere;
		}

		& .project-item {
			display: grid;
			grid-template-columns: 10.5% 16.7% 12% 11.5% 9.3% 9% 9% 22%;
			min-width: 1010px;

			align-items: center;
			min-height: 2rem;

			& .project-sub {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				align-content: center;
				justify-content: center;
				align-items: flex-start;

				& .sub-item {
					width: 100%;
					height: 2rem;
					padding-left: 0.5rem;
					display: flex;
					align-items: center;
					border-bottom: solid 1px $background-color;
				}

				& .sub-item:last-child {
					border-bottom: none;
				}
			}
			/*
      & .proj-detail-link {
        display: grid;height: 100%;
        grid-template-columns: 15% 37% 16% 16% 16%;
        text-decoration: none;
      }
*/
		}

		& .no-sub {
			grid-template-columns: 10.5% 20.7% 14% 21.5% 11.3% 11% 11%;
		}
	}

	& a {
		text-decoration: none;
	}
}

.__react_component_tooltip.show {
	width: 50%;
}

@media screen and (max-width: $screen-xl-min) {
	.ProjectsList {
		& .projects-container {
			& .projectsList-title {
				min-width: 1025px;

				& .title {
					font-size: medium;
				}
			}

			& tbody {
				font-size: small;
			}

			& .project-item {
				font-size: small;
			}
		}
	}
}
