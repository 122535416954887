@import './src/assets/scss/variables';

.SubventionRelationInfos {
	& .sub-accounting-infos {
		display: grid;
		grid-template-columns: 25% 75%;
		align-items: center;
		justify-items: start;
		min-height: 12rem;
		padding: 1rem;
		grid-row-gap: 15px;

		& .accounting-item-accounting_product {
			grid-area: 1/1/1/1;
			width: 100%;
			display: grid;
			grid-template-columns: 150px 20%;
			align-content: center;
			align-items: center;
			justify-items: start;
		}

		& .accounting-item-accounting_tiers {
			grid-area: 1/2/1/2;
			display: grid;
			grid-template-columns: 130px 20%;
			align-content: center;
			align-items: center;
			justify-items: start;
			width: 100%;
		}

		& .accounting-item-recievers {
			grid-area: 3/1/3/3;
			display: flex;
			align-content: center;
			justify-items: start;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;

			& select {
				margin: 0 0.5rem;
			}

			& svg,
			#recievers {
				margin-left: 0.5rem;
				justify-self: flex-start;
				cursor: pointer;
			}

			& .recievers-list {
				margin: 1rem 0;
			}
		}

		& .accounting-item-projects {
			grid-area: 2/1/2/3;
			display: flex;
			align-items: center;
			justify-items: start;
			flex-direction: row;
			flex-wrap: wrap;
			align-content: center;

			& select {
				margin: 0 0.5rem;
			}

			& svg {
				margin-left: 0.5rem;
				justify-self: flex-start;
				cursor: pointer;
			}

			& .projects-list {
				display: flex;
				margin: 1rem 0;

				& input {
					width: 50px;
				}

				& .suffix {
					display: flex;
					width: auto;
				}

				& .input-group-addon {
					padding: 6px 12px;
					font-size: 14px;
					font-weight: 400;
					line-height: 1;
					color: #555;
					text-align: center;
					background-color: #eee;
					border: 1px solid #ccc;
					width: 1%;
					white-space: nowrap;
					vertical-align: middle;
					display: table-cell;
				}

				& .suffix .input-group-addon {
					border-left: 0;
					border-radius: 0 8px 8px 0;
					display: flex;
					flex-direction: row;
					align-content: center;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.SubventionRelationInfos {
		& .sub-accounting-infos {
			grid-template-columns: 50% 50%;
		}
	}
}
