@import '../../../assets/scss/variables';

.AddParticipantModal {
	& .modal-content {
		color: $sheet-title;

		& .modal-header {
			& .modal-title {
			}
		}
		& .modals-body {
			display: flex;
			justify-content: center;
			padding: 2rem 0;

			& form {
				display: flex;
				flex-wrap: wrap;
				width: 80%;

				& .addParticipantContainer {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-self: center;
					margin: 1rem;
					width: 41%;
				}

				& .address {
					width: 100%;
				}

				& .present {
					width: 98%;
					flex-direction: row;

					& label {
						height: 100%;
						margin: auto 0.5rem;
					}
				}

				& label {
					margin-bottom: 0.5rem;
				}
				& input {
					padding-left: 0.5rem;
				}

				& .editButtons {
					justify-self: center;
					margin-bottom: 2rem;
					width: 100%;
				}
			}
		}
	}
}
