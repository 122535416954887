@import '../../../../../assets/scss/variables';

.UsersTab {
	& .User-container {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		& svg {
			align-self: end;
		}
	}
}
