@import './src/assets/scss/variables';

.ProjectDangerZone {

  & .project-danger-zone {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    border: 3px solid pink;
    align-content: space-around;

    & .del-project {
      width: 20%;
      display: flex;
      align-items: center;

      & label {
        margin-right: 1rem;
      }

      & input {
        width: 1.5rem;
      }

      & div.advert {
        width: 100%;
        align-self: center;
        justify-self: center;
      }
    }
  }
}