@import './src/assets/scss/variables';

.AskPasswordReset {
	margin-top: 5rem;
	font-family: 'Roboto', 'Open Sans', 'sans-serif';
	color: $text-color;

	& h1 {
		margin-bottom: 5rem;
	}

	& .message_container_loader {
		height: 2rem;
		width: 2rem;
		margin: auto;
		margin-bottom: 2rem;
	}

	& .message_container {
		height: 2rem;
		margin-bottom: 2rem;
		text-align: center;
	}

	& .message {
		margin-bottom: 2rem;
		font-size: x-large;
		font-weight: bolder;
	}

	& .AskPasswordReset-form {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: space-around;
		align-items: center;
		width: 25em;
		margin: auto;

		& .email-container {
			margin-bottom: 1rem;
			width: 83%;

			& label {
				font-size: larger;
				width: 100%;
				height: 1.5rem;
				grid-column: 1;
			}

			& input {
				width: 100%;
				height: 2rem;
				grid-column: 1;
				padding-left: 0.5rem;
			}
		}

		& .return {
			text-align-last: end;
			width: 83%;
		}

		& .AskPasswordReset_buttons {
			width: inherit;
			margin-top: 2rem;
		}
	}
}
