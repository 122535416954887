@import '../../../assets/scss/variables';

.sm-modal {
	--bs-modal-width: 800px;
}

.add-modals {
	& .modal-content {
		& .modal-body {
			& .modal__body {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 1rem;

				& form {
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 2rem;

					& fieldset {
						width: 75%;
						display: flex;
						flex-direction: column;
						gap: 1rem;

						& .form-label {
							display: flex;

							& svg {
								align-self: start;
								margin-left: 0.5rem;
								color: $button-danger;
							}
						}
					}
				}
			}
		}
	}
}
