@import './src/assets/scss/variables';

.AddSelfFinancingModal {

  & .modal-content {

    & .addSelfBody {
      width: 80%;
      align-self: center;
      padding: 1rem;

      & .addSelfContainer {
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
      }

      & #origin {
        padding-left: 10px;
      }

      & #amount {
        text-align: end;
        padding-right: 10px;
      }

      & .Buttons {
        margin: 2rem 0;
      }
    }
  }
}