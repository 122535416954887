@import "./src/assets/scss/variables";

.Dashboard {
  font-family: "Roboto", "Open Sans", sans-serif;
  display: grid;
  grid-template-columns: 90% 10%;
  align-content: center;
  align-items: center;

  & .sub-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
  }

  & .tab-content {
    padding: 0.5rem 0;
    background-color: $dashboard-background;
    border-radius: 0 8px 8px 8px;
    height: 75vh;
    grid-area: 2/1/2/3;
    overflow: hidden;

    & .tab-pane {
      margin: 1rem;

      & .PageSubventions,
      .ProjectsList {
        overflow-x: auto;
        overflow-y: auto;
        padding: 0;
        max-height: 60vh;
      }
    }
  }

  & .nav-tabs {
    grid-area: 1/1/1/1;

    & li > button {
      font-size: larger;
    }
  }

  & .nav-tabs .nav-link {
    border-radius: 8px 8px 0 0;
  }

  & .nav-link {
    font-size: x-large;
    color: $background-color;
    font-weight: bolder;
    background-color: $sheet-title;
    height: 3rem;
  }

  & .nav-link.active {
    font-weight: bolder;
    color: $sheet-title;
    background-color: $dashboard-background;
  }

  & .buttons {
    grid-area: 1/2/1/2;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    width: 180px;
    justify-self: end;

    & div {
      margin-left: 1rem;
    }

    & .hiden {
      opacity: 0;
      width: 3rem;
    }

    & svg {
      transform: scale(1);
      //margin: 0 1rem;
      cursor: pointer;
      height: 35px;
      width: 35px;
    }
  }


}

@media screen and (max-width: $screen-xxl-min) {
  .Dashboard {
    & svg {
      transform: scale(1);
    }

    & .nav-tabs {
      margin: 0;

      & .nav-link {
        height: 2.5rem;
      }
    }

    & .tab-content {
      margin: 0;

      & .tab-pane {
        margin: 1rem;
      }
    }
  }
}

@media screen and (max-height: $screen-lg-min) {
  .Dashboard {
    & .tab-content {
      height: 70vh;
    }
  }
}

@media screen and (min-height: $screen-xl-min) {
  .Dashboard {
    & .tab-content {
      height: 80vh;
    }
  }
}
