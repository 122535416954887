@import './src/assets/scss/variables';

.Message {
	height: 2rem;
	margin-bottom: 2rem;
	text-align: center;

	& .message {
		margin-bottom: 2rem;
		font-size: x-large;
		font-weight: bolder;
	}

	& .error {
		color: $button-danger;
	}

	& .success {
		color: $sheet-title;
	}
}
