@import './src/assets/scss/variables';

.SubventionFinancialInfos {

  & .sub-financial-infos {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: center;
    justify-items: start;
    min-height: 12rem;
    padding: 1rem;
    grid-row-gap: 30px;

    & .payment-item-1 {
      margin-bottom: 0.5rem;
    }

    & div > div {
      color: $text-color;
      font-weight: normal;
    }

    & input {
      text-align: end;
    }

    & .financial-infos-amount-asked {
      grid-area: 1/1/1/1;
      width: 100%;
      display: grid;
      grid-template-columns: 45% 40%;
      align-items: center;

      & div {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        align-items: center;
      }
    }

    & .financial-infos-amount_contracted {
      grid-area: 1/2/1/2;
      width: 100%;
      display: grid;
      grid-template-columns: 45% 40%;
      align-items: center;

      & div {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        align-items: center;
      }
    }

    & .financial-infos-total_recieved {
      grid-area: 1/3/1/3;
      width: 100%;
      display: grid;
      grid-template-columns: 25% 50%;
    }

    & .financial-infos-remains {
      grid-area: 1/4/1/4;
      width: 100%;
      display: grid;
      grid-template-columns: 45% 50%;

      & label {
        justify-self: flex-end;
        margin-right: 1rem;
      }
    }

    & .financial-infos-give_back {
      grid-area: 2/1/2/1;
      width: 100%;
      display: grid;
      grid-template-columns: 45% 50%;
    }

    & .financial-infos-counted {
      grid-area: 2/2/2/2;
      width: 100%;
      display: grid;
      grid-template-columns: 30% 50%;

      & label {
        align-self: center;
      }
    }

    & .financial-infos-percent {
      grid-area: 2/4/2/4;
      width: 100%;
      display: grid;
      grid-template-columns: 45% 50%;

      & label {
        justify-self: flex-end;
        margin-right: 1rem;
      }
    }

    & .financial-infos-payments {
      grid-area: 3/1/3/5;
      width: 100%;
      display: grid;
      grid-template-columns: 7% 2% 93%;
      grid-column-gap: 10px;

      & div#payments {
        grid-column: 3;
        width: 100%;
        display: grid;
        grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
        row-gap: 7px;
        //grid-template-rows: 50% 50%;

        & .payment-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 0.5rem;

          & .payment-item-2 {
            justify-self: center;
          }
        }
      }

      & svg {
        grid-column: 2;
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: $screen-xxl-min) {
  .SubventionFinancialInfos {
    & .sub-financial-infos {
      grid-row-gap: 20px;

      & .financial-infos-amount-asked,
      .financial-infos-amount_contracted,
      .financial-infos-total_recieved,
      .financial-infos-remains,
      .financial-infos-give_back,
      .financial-infos-percent {
        display: flex;
        flex-direction: column;

        & label {
          align-self: start;
          margin-bottom: 0.5rem;
        }

        & div {
          height: 2rem;
          justify-self: center;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
        }
      }

      & .financial-infos-payments {
        grid-template-columns: 115px 3% 80%;
        margin-bottom: 1rem;

        & div#payments {
          grid-template-columns: 20% 20% 20% 20% 20%;
        }
      }
    }

  }

}

