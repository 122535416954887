@import './src/assets/scss/variables';

.Notification {
  width: 100%;
  display: grid;
  grid-template-columns: 13% 29% 13% 18% 260px 4%;
  margin-bottom: .5rem;
  align-items: center;

  & .notification-ref {
    grid-area: 1/1/1/1;
  }
  & .notification-name {
    grid-area: 1/2/1/2;
  }
  & .notification-type {
    grid-area: 1/3/1/3;
  }
  & .notification-date {
    grid-area: 1/4/1/4;
  }
  & .notification-button {
    grid-area: 1/5/1/5;
  }
  & .notification-eye {
    display: none;
    grid-area: 1/5/1/5;
  }
  & .notification-alert {
    grid-area: 1/6/1/6;
    display: flex;
    justify-content: center;
    align-items: center;

    & .notification-svg {
      width: 80%;
      height: 80%;
      animation: pulse 1s infinite;
    }


    @keyframes pulse {
      0% {
        color: $alert1;
      }
      100% {
        color: $alert2;
      }
    }
  }
}

.new > th {
  color: $button-hover;
  font-weight: bold;
}


@media screen and ( max-width: $screen-xl-min ) {
  .Notification {
    width: 100%;
    display: grid;
    grid-template-columns: 160px 350px 150px 220px 35px 35px;


    & .notification-button {
      display: none;
    }
    & .notification-eye {
      display: block;
      grid-area: 1/5/1/5;
      color: $button-color;
    }


  }

}
