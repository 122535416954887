@import './src/assets/scss/variables';

.Reminder {



  & svg.notif {
    width: 10px;
    position: relative;
    top: -10px;
    left: -5px;
  }

  & svg.bell {
    color: $text-color;

  }

  & svg.new-notif {
    color: $button-danger;

  }

  & svg.circle.new-notif {
    width: 10px;
    position: relative;
    top: -10px;
    left: -5px;
  }
}

div.modal_reminder {
  color: $text-color;
  max-width: 70vw;
  & .modal-content{

    & div.modal-header{

      .modal-title{
        font-size: xxx-large;
      }
    }

    & .modal-body {
      font-size: x-large;
      max-height: 500px;
      overflow-y: auto;

      & .reminder-text {
        font-size: xx-large;
        margin-bottom: 2rem;
      }

      & h2 {
        margin-bottom: 1rem;
      }

      & table {
        margin-bottom: 1.5rem;
        border-spacing: 0px 10px;
        width: 100%;

        border-collapse:separate;

        & thead {
          height: 2rem;
          font-size: 1.7rem;
          font-weight: bold;
        }

        & th {
          margin-right: .5rem;
        }



        & button {
          padding: 0 1rem;
          height: 2.5rem;
          width: fit-content;
        }
      }
    }

    & .modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      & button {
        padding: 0 1rem;
        height: 3rem;
        width: 5rem;
      }
    }
  }
}

@media screen and ( max-width: $screen-xxl-min ) {

  div.modal_reminder {
    color: $text-color;
    max-width: 90vw;
    & .modal-content{

      & div.modal-header{

        .modal-title{
          font-size: xxx-large;
        }
      }

      & .modal-body {
        font-size: x-large;
        max-height: 500px;
        overflow-y: auto;

        & .reminder-text {
          font-size: xx-large;
          margin-bottom: 2rem;
        }

        & h2 {
          margin-bottom: 1rem;
        }

        & table {
          margin-bottom: 1.5rem;
          border-spacing: 0px 10px;
          width: 100%;

          border-collapse:separate;

          & thead {
            height: 2rem;
            font-size: 1.7rem;
            font-weight: bold;
          }

          & th {
            margin-right: .5rem;
          }
        }
      }
    }
  }
}