@import "./src/assets/scss/variables";

.Header {
  font-family: "Roboto", "Open Sans", sans-serif;
  width: 100%;
  display: grid;
  grid-template-columns: 10% 75% 14%;
  margin-bottom: 2rem;

  @media screen and (max-height: 667px) {
    height: 17vh;
  }

  & .logo {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    grid-column: 1;

    & img {
      transform: scale(0.8);
      width: 150px;
    }

    @media screen and (max-height: 667px) {
      margin: 0;
      grid-column: 1;
      display: flex;
      justify-self: start;

      & img {
        transform: scale(0.5);
        width: 185px;
        height: 140px;
      }
    }
  }

  & .container {
    grid-column: 2;
    margin: auto;
    width: 100%;
    height: 100%;
  }

  & .user-infos {
    grid-column: 3;
    display: grid;
    grid-template-rows: 30% 15% 15% 25%;
    grid-template-columns: 33% 33% 33%;
    column-gap: 10px;

    & .user-firstname {
      grid-area: 2 / 2 / 2 / 2;
      justify-self: flex-end;
      align-self: flex-end;
      margin-right: 0.3rem;
    }

    & .user-lastname {
      grid-area: 3 / 2 / 3 / 2;
      justify-self: flex-end;
      align-self: flex-end;
      padding-bottom: 0.2rem;
      margin-right: 0.3rem;
      color: $link-loggout-hover;
    }

    & svg {
      grid-area: 2/3/2/3;
      align-self: center;
      justify-self: center;
    }

    & .loggout {
      grid-area: 4/3/4/4;
      justify-self: center;
      align-self: center;
    }

    & a.loggout:hover {
      color: $link-loggout-hover;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $screen-xxl-min) {
  .Header {
    grid-template-columns: 10% 74% 16%;

    & .logo {

    }

    & .user-infos {
      grid-column: 3;
      display: grid;
      grid-template-rows: 30% 15% 15% 25%;
      grid-template-columns: 60% 40%;
      column-gap: 0;
      margin-right: 2%;

      & .user-firstname {
        grid-area: 2 / 1 / 2 / 1;
        justify-self: flex-end;
        align-self: flex-end;
        margin-right: 0.3rem;
      }

      & .user-lastname {
        grid-area: 3 / 1 / 3 / 1;
        justify-self: flex-end;
        align-self: flex-end;
        padding-bottom: 0.2rem;
        margin-right: 0.3rem;
        color: $link-loggout-hover;
      }

      & svg {
        grid-area: 2/2/4/2;
        align-self: center;
        justify-self: flex-end;
        height: 47px;
        width: 47px;
      }

      & .loggout {
        grid-area: 4/1/4/3;
        justify-self: flex-end;
        align-self: center;
      }

      & a.loggout:hover {
        color: $link-loggout-hover;
        cursor: pointer;
      }
    }
  }
}
