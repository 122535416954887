@import './src/assets/scss/variables';

.SubventionDetail {
  font-family: "Roboto", "Open Sans", sans-serif;
  overflow-y: auto;
  max-height: 67vh;
  min-height: 6vh;

  & svg {
    justify-self: end;
    margin-right: 1rem;
    color: $button-color;
  }

  & h2 {
    margin-left: 3rem;
    margin-bottom: 2rem;
  }

  & .return-button {
    margin-left: 3rem;
    margin-bottom: 5rem;
    font-size: large;
  }

	& select,
	input,
	textarea {
		border: solid 1px $detail-text-color-grey;
		padding: 0 0.5rem;
		width: 80%;
	}

  & span {
    color: $detail-text-color;
    font-weight: normal;
    margin: 0 0.5rem;
  }

  & section {
    color: $detail-text-color;
    font-size: large;
    font-weight: bolder;
    background-color: #fff;
    width: 95%;
    margin: 1rem auto;
    min-height: fit-content;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    & select {
      width: fit-content;

    }

    & select.accounting-item-accounting_tiers_select {
      width: 100%;
    }
  }

  & .editButtons {
    width: 50%;
    margin: 2rem auto;

    & button {
      height: 3.5rem;
    }
  }

  .modal-header {
    display: grid;
    grid-template-columns: 95% 5%;
  }

  & .loading {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: $Page-title;

    & .spinner-text {
      margin-left: .5em;
    }
  }
}

@media screen and (max-width: $screen-xxl-min) {
  .SubventionDetail {

  }
}

@media screen and (min-width: $screen-xl-min) {
  .SubventionDetail {

  }
}

@media screen and (max-height: $screen-xl-min) {
  .SubventionDetail {
    max-height: 65vh;
  }
}

@media screen and (max-height: $screen-lg-min) {
  .SubventionDetail {
    max-height: 60vh;
  }
}

@media screen and (min-height: $screen-xl-min) {
  .SubventionDetail {
    max-height: 70vh;
  }
}
