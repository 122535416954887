@import '../../assets/scss/variables';

.ProjectParticipants {
	& .no-participant {
		padding-left: 2rem;
	}

	& .project-participants {
		& .header {
			display: flex;
			margin-bottom: 1rem;

			& h3 {
				margin-right: 1rem;
			}
		}

		& table {
			display: flex;
			flex-direction: column;
			gap: 0.2rem;

			& th {
				padding: 0 0.5rem;
				display: flex;
				align-items: center;
			}

			& .participants-title {
				background-color: $table-head;
				color: #fff;
				height: 1.7em;
				margin-bottom: 0.5em;
				& tr {
					& th {
						height: 2rem;
					}
				}
			}

			& .participants-list {
				& tr {
					& th {
						height: 1.5rem;

						& input {
							height: max-content;
							width: 90%;
							border: 1px solid $return-button;
						}

						& .switch {
							& .react-switch-handle {
							}
						}
					}

					& .beneficiary-buttons {
						display: flex;
						justify-content: space-evenly;
						align-items: center;
					}

					& svg {
						margin-right: 0;
					}
				}

				& .Message {
					margin: 0.5rem 0;
				}
			}

			& .table-row {
				display: grid;
				grid-template-columns: 15% 15% 18% 10% 10% 11% 9% 6% 6%;
			}
		}
	}
}

//@media screen and (max-width: $screen-xxl-min) {
//	.ProjectParticipants {
//		& .project-participants {
//			& table {
//				& .table-row {
//					display: grid;
//					grid-template-columns: 15% 15% 18% 10% 10% 11% 9% 6% 6%;
//				}
//			}
//		}
//	}
//}

@media screen and (max-width: $screen-lg-min) {
	.ProjectParticipants {
		& .project-participants {
			& table {
				& .table-row {
					display: grid;
					grid-template-columns: 26% 26% 29% 11% 8%;
				}

				& .not-displayed {
					display: none;
				}
			}
		}
	}
}
