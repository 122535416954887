@import './src/assets/scss/variables';

.BackOfficeModal {
}

.add-modals {
	color: $text-color;

	& .modal-content {
		& div.modal-header {
			& .btn-close {
				margin: 0.5rem;
			}

			& .modal-title {
				font-size: xx-large;
				margin: auto;
			}
		}

		& div.modal-body {
			& .error_message {
				text-align: center;
				color: $alert1;
				font-size: x-large;
				margin-bottom: 2rem;
			}

			& form {
				display: grid;
				grid-template-columns: 50% 50%;
				justify-content: center;

				& fieldset {
					& input,
					select {
						height: fit-content;
						min-height: 2.1rem;
					}
				}

				& fieldset.new-user-infos,
				fieldset.new-user-group,
				fieldset.new-user-rights,
				fieldset.new-user-projects {
					height: -webkit-fill-available;
					width: 100%;
					background-color: $modal-background;
					padding: 1rem;
					margin: 2rem 0 1rem 0;

					& div.mb-3 {
						width: 60%;
						margin: 1rem auto;
					}

					& div.switchs {
						width: 60%;
						margin: 1rem auto;
						display: flex;
						justify-content: space-between;
						align-items: center;
					}
				}

				& fieldset.lined {
					height: 0.5rem;
					border-bottom: 1px solid $background-color;
					grid-area: 2/1/2/3;
				}

				& fieldset.new-user-group,
				fieldset.new-user-projects {
					border-left: 1px solid $background-color;
				}

				& fieldset > h3 {
					font-size: large;
					text-align: center;
					font-weight: bold;
					color: $detail-text-color;
					margin-bottom: 2rem;
				}

				& fieldset.new-user-group,
				fieldset.new-user-status {
				}

				& fieldset.new-user-rights,
				fieldset.new-user-status {
				}

				& fieldset.new-assos {
					grid-column: 1/3;
					width: 50%;
					justify-self: center;
				}

				& .new-user-infos,
				.new-user-status {
					& .mb-3 {
						display: grid;
						grid-template-columns: 95% 5%;

						& label,
						select,
						input {
							grid-column: 1;
						}

						& svg {
							width: 0.5rem;
							justify-self: center;
							color: red;
						}
					}
				}

				& .new-user-group {
				}

				& .new-user-rights {
				}

				& fieldset.new-user-buttons,
				.new-association-buttons,
				.new-receiver-buttons {
					grid-area: 4/1/4/3;
					display: flex;
					justify-content: center;

					& button {
						margin: 1rem;
						height: 3rem;
					}

					& button:disabled {
						background-color: #78777d;
					}

					& button.cancel {
						background-color: #78777d;
					}

					& button.cancel:hover {
						background-color: #776491;
					}
				}
			}

			& .reciever-form {
				display: flex;
				margin: auto;
				flex-direction: column;
				width: 60%;
				align-items: center;

				& fieldset {
					width: 100%;

					& div {
						display: flex;
						flex-wrap: wrap;
					}

					& label {
						width: 98%;
						order: 1;
					}

					& input,
					select {
						width: 90%;
						order: 2;
						margin-right: 0.5rem;
					}

					& svg {
						order: 3;
						width: 0.5rem;
						justify-self: center;
						color: red;
					}
				}
			}
		}
	}
}
