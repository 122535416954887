@import '../../../assets/scss/variables';

.AddPaymentModal {

  & .modal-content {
    color: $text-color;

    & .modal-header {
      display: grid;
      grid-template-columns: 90% 3%;
      justify-items: center;
    }

    & .modal-body {
      & form {
        display: flex;
        flex-direction: column;
        align-items: center;


        & div {
          margin-bottom: 1rem;
        }

        & .message {
          grid-area: 1/1/1/3;
          justify-self: center;
          color: red;
          font-size: large;
        }

        & .form-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          width: 80%;

          & label {
            margin-right: .5rem;
          }

          & input {
            padding-left: 0.5rem;
          }

          & svg {
            align-self: self-start;
            margin-left: .5rem;
          }

          & #amount {
            width: 50%;
          }
        }

        & .form-start-date {
          grid-area: 2/1/2/1;
        }

        & .form-amount {
          grid-area: 2/2/2/2;

        }

        & .advertising,
        .error-message {
          grid-area: 3/1/3/4;
          justify-self: center;
          color: $button-danger;
          margin-bottom: 1rem;
        }

        & .editButtons {
          justify-self: center;
          margin-bottom: 2rem;
          width: 100%;
        }
      }
    }
  }
}
