@import './src/assets/scss/variables';

.ProjectTotals {
	& .total_container {
		display: flex;
		//justify-content: space-between;
		gap: 20rem;
	}

	& input {
		margin: 0.5rem;
		text-align: center;
	}

	& thead > tr > th {
		text-align: center;
	}

	& .financial_totals {
		width: 25em;

		& tbody {
			justify-self: end;
		}
	}

	& .financial_totals_rows {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
	}

	& .great_total {
		font-size: x-large;
		font-weight: bold;
	}

	& .budget_table {
		& .row_name {
			padding-right: 1rem;
			justify-content: center;
		}
	}
}

@media screen and (max-width: $screen-xl-min) {
	.ProjectTotals {
		& .total_container {
			gap: 5rem;
		}

		& .financial_totals,
		.budget_table {
			width: 50%;
		}

		& .budget_table {
			& .row_name {
				padding-right: 0;
				justify-content: center;
			}

			td > input {
				width: 100%;
			}
		}
	}
}
