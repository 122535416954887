@import '../../assets/scss/variables';

.Totaux {
	font-family: 'Roboto', 'Open Sans', sans-serif;
	color: $text-color;
	display: flex;
	margin-top: 1.5rem;
	align-content: center;
	align-items: center;
	justify-content: center;

	& h1 {
		align-self: center;
		color: $sheet-title;
		font-weight: bolder;
		margin-right: 2rem;
		font-size: x-large;
	}

	& .detail-totaux {
		display: none;
	}

	& .totaux {
		display: flex;
		background-color: #fff;
		border-radius: 8px;
		padding: 0.5em;
		justify-items: start;
		width: 80%;

		& h2 {
			font-size: large;
			width: 100%;
		}

		& .asked,
		.accepted {
			width: 50%;
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;
		}

		//& .total-sub-container {
		//	display: flex;
		//	flex-direction: column;
		//
		//	width: 70%;
		//
		//	& label {
		//		margin-bottom: 0.3rem;
		//		font-size: large;
		//		text-align: center;
		//	}
		//
		//	& .totaux-read {
		//		font-weight: bold;
		//		text-align: center;
		//		font-size: larger;
		//		height: 1.5rem;
		//		color: $sheet-title;
		//	}
		//
		//	& .big_int {
		//		grid-area: 2/1/2/3;
		//	}
		//}
	}
}

@media screen and (max-width: $screen-md-min) {
	.Totaux {
		& h1 {
			font-size: medium;
		}

		& .totaux {
			& h2 {
				font-size: small;
			}
			& .total-sub-container {
				display: flex;
				flex-direction: column;
			}

			& .TotalCard {
				font-size: x-small;
			}
		}

		& .detail-totaux {
			display: flex;
			justify-content: center;

			& summary {
				text-align: center;
			}

			& .totaux {
				margin: auto;
				z-index: 99;
			}
		}

		& .without-detail {
			display: none;
		}
	}
}

@media screen and (max-width: $screen-xl-min) {
	.Totaux {
		& h1 {
			font-size: x-large;
		}

		& .totaux {
			& .total-sub-container {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
