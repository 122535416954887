@import '../../assets/scss/variables';

.SubventionGeneralInfos {
	& .sub-general-infos {
		flex-direction: row;
		flex-wrap: wrap;
		padding: 1rem;

		& div {
			margin: 0.5rem;
			height: fit-content;
			min-height: 3rem;
			display: flex;
			align-items: center;

			& label {
				margin-right: 0.5rem;
			}
		}

		& .sub-general-item {
			width: 32%;
		}

		& .sub-ref {
			order: 1;
		}

		& .sub-name {
			order: 2;

			& label {
				max-width: 40%;
			}
		}

		& .sub-manager {
			order: 3;
		}

		& .sub-status {
			order: 4;
		}

		& .sub-etat {
			order: 5;
		}

		& .sub-versement {
			order: 6;
		}

		& .sub-type {
			order: 7;

			& label {
				max-width: 40%;
			}
		}

		& .sub-funder {
			order: 8;

			& label {
				max-width: 40%;
			}
		}

		& .sub-ref2 {
			order: 9;
			width: 100%;

			& input {
				width: fit-content;
				max-width: 20%;
			}
		}

		& .sub-start_date {
			order: 10;

			& input {
				width: fit-content;
			}
		}

		& .sub-end_date {
			order: 11;

			& input {
				width: fit-content;
			}
		}

		& .sub-duration {
			order: 12;

			& .duration-content {
				display: flex;
			}
		}

		& .sub-N_duration {
			order: 13;

			& .duration-content {
				display: flex;
			}
		}

		& .accounting-item-accounting_product {
			order: 15;

			& label {
				max-width: 40%;
			}
		}

		& .accounting-item-accounting_tiers {
			order: 16;

			& label {
				max-width: 40%;
			}
		}

		#accountingProduct,
		#accountingTier {
			margin-right: 1rem;
		}

		& .sub-description {
			//display: flex;
			//flex-direction: column;
			align-items: start;
			row-gap: 1rem;
			order: 14;
			width: 100%;
			height: auto;

			& textarea {
				flex: 1 1;
				line-height: 1.5;
				height: auto;
			}
		}
	}
}

@media screen and (min-width: $screen-xxl-min) {
	.SubventionGeneralInfos {
		& .sub-general-infos {
			flex-direction: row;
			flex-wrap: wrap;
			padding: 1rem;

			& div {
				margin: 0.5rem;
				height: 3rem;
				display: flex;
				align-items: center;

				& label {
					margin-right: 0.5rem;
				}
			}

			& .sub-general-item {
				width: 23%;
			}

			& .accounting-item-accounting_product,
			.accounting-item-accounting_tiers {
				width: 45%;
			}

			& .sub-versement {
				width: 46%;
			}

			& .sub-ref2 {
				width: 46%;
			}

			& .sub-description {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.SubventionGeneralInfos {
		& .sub-general-infos {
			flex-direction: row;
			flex-wrap: wrap;
			padding: 1rem;

			& div {
				margin: 0.5rem;
				height: 3rem;
				display: flex;
				align-items: center;

				& label {
					margin-right: 0.5rem;
				}
			}

			& .sub-general-item {
				width: 32%;
			}

			& .sub-ref2 {
				width: 64%;
			}

			& .sub-end_date {
				width: 64%;
			}

			& .sub-N_duration {
				width: 64%;
			}

			& .sub-description {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $screen-xl-min) {
	.SubventionGeneralInfos {
		& .sub-general-infos {
			flex-direction: row;
			flex-wrap: wrap;
			padding: 1rem;

			& div {
				margin: 0.5rem;
				height: 3rem;
				display: flex;
				align-items: center;

				& label {
					margin-right: 0.5rem;
				}
			}

			& .sub-general-item {
				width: 48%;
			}

			& .sub-ref {
				order: 1;
			}

			& .sub-name {
				order: 2;
			}

			& .sub-manager {
				order: 3;
			}

			& .sub-status {
				order: 4;
			}

			& .sub-etat {
				order: 5;
			}

			& .sub-versement {
				order: 6;
			}

			& .sub-type {
				order: 7;
			}

			& .sub-funder {
				order: 8;
			}

			& .sub-ref2 {
				order: 9;
			}

			& .sub-start_date {
				order: 10;
			}

			& .sub-end_date {
				order: 11;
			}

			& .sub-duration {
				order: 12;

				& .duration-content {
					display: flex;
				}
			}

			& .sub-N_duration {
				order: 13;

				& .duration-content {
					display: flex;
				}
			}

			& .accounting-item-accounting_product {
				order: 15;
			}

			& .accounting-item-accounting_tiers {
				order: 16;

				& select {
					width: 50%;
				}
			}

			& .sub-description {
				order: 14;
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $screen-md-min) {
	.SubventionGeneralInfos {
		& .sub-general-infos {
			flex-direction: row;
			flex-wrap: wrap;
			padding: 1rem;

			& div {
				margin: 0.5rem;
				height: 3rem;
				display: flex;
				align-items: center;

				& label {
					margin-right: 0.5rem;
				}
			}

			& .sub-general-item {
				width: 98%;
			}

			& .sub-ref {
				order: 1;
			}

			& .sub-name {
				order: 2;
			}

			& .sub-manager {
				order: 3;
			}

			& .sub-status {
				order: 4;
			}

			& .sub-etat {
				order: 5;
			}

			& .sub-versement {
				order: 6;
			}

			& .sub-type {
				order: 7;
			}

			& .sub-funder {
				order: 8;
			}

			& .sub-ref2 {
				order: 9;
			}

			& .sub-start_date {
				order: 10;
			}

			& .sub-end_date {
				order: 11;
			}

			& .sub-duration {
				order: 12;

				& .duration-content {
					display: flex;
				}
			}

			& .sub-N_duration {
				order: 13;

				& .duration-content {
					display: flex;
				}
			}

			& .accounting-item-accounting_product {
				order: 15;
			}

			& .accounting-item-accounting_tiers {
				order: 16;

				& select {
					width: 50%;
				}
			}

			& .sub-description {
				order: 14;
				width: 100%;
			}
		}
	}
}
