@import '../../../assets/scss/variables';

.add-project {
	& .modal-content {
		font-size: x-large;
		max-height: 90vh;
		overflow-y: auto;

		& .form-select {
			padding-right: 2rem;
		}

		& svg {
			align-self: flex-start;
			margin-left: 0.5rem;
		}

		& button.btn-close {
			margin: 0;
		}

		& .modal-body > form.CreateProjectModal {
			display: flex;
			flex-wrap: wrap;
			gap: 2rem;

			& .section-grid {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 2rem;
			}

			& .section-buttons {
				justify-content: center;
				align-items: center;
			}

			& .form-group {
				display: grid;
				grid-template-columns: 75% 25%;
				grid-template-rows: 50% 50%;
				width: 50%;

				& label {
					grid-area: 1/1/1/1;
				}

				& div,
				select,
				input {
					grid-area: 2/1/2/1;
				}

				& .asterisk {
					grid-area: 2/2/2/2;
					margin-left: 0.5rem;
				}
			}

			& .form-manager {
				flex-wrap: wrap;
				& .form-manager-label {
					width: 100%;
				}

				& .section-manager {
					padding-left: 1rem;
				}
			}

			& .form-message {
				grid-area: 6/1/6/3;
				color: $alert1;
				margin: 0 auto;
				margin-bottom: 1rem;
			}
		}

		& .buttons {
			grid-area: 7/1/7/3;
			margin: auto;
			width: 50%;
		}
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.add-project {
		& .modal-content {
			& .modal-body > form.CreateProjectModal {
			}
		}
	}
}

@media screen and (max-height: $screen-lg-min) {
	.add-project {
		max-width: 1000px;
	}
}

@media screen and (min-height: $screen-xl-min) {
}

@media screen and (max-width: $screen-lg-min) {
	.add-project {
		& .modal-content {
			& .modal-body > form.CreateProjectModal {
				font-size: small;
			}
		}
	}
}
