@import '../../../../assets/scss/variables';

.AccountForm {
	display: flex;
	flex-direction: column;
	padding: 1rem 2rem;
	justify-content: center;
	align-items: center;
	color: $text-color;

	& h3 {
		font-size: xx-large;
		margin-bottom: 3rem;
	}

	& .form-advertizer {
		margin-bottom: 2rem;
		font-size: larger;
		font-weight: bold;
	}

	& label,
	input,
	.buttonsWidth {
		margin-bottom: 1rem;
		width: 50%;
	}

	& input {
		padding-left: 0.5rem;
	}

	& .switch-content {
		width: 50%;

		& label {
			width: auto;
			margin: 0 1rem 0 0;
		}
	}
	& .react-switch-bg {
		padding: 0 0.5rem;
		& div {
			width: 100% !important;
		}
	}

	& .switch-item {
		height: 100%;
		display: flex;
		align-content: center;
		align-items: center;
		padding: 0 0.2rem;
		justify-content: center;
	}

	& .switch-inactive {
		color: #fff;
	}

	& .switch-active {
		color: #fff;
	}

	& .fetch-error {
		height: 3rem;
		color: $alert1;
		font-weight: bold;
		font-size: large;
	}

	& .editButtons {
		display: flex;
		justify-content: space-evenly;
		width: 50%;
		margin-top: 2rem;

		& button {
			height: 3rem;
		}

		& .cancel {
			background: $return-button;
		}
	}
}

@media screen and (max-height: $screen-xl-min) {
}

@media screen and (max-height: $screen-lg-min) {
	.AccountForm {
		& h3 {
			font-size: larger;
			margin-bottom: 1.5rem;
		}

		& .form-advertizer {
			margin-bottom: 1rem;
			font-size: large;
			font-weight: bold;
			width: 80%;
		}

		& label,
		input,
		.buttonsWidth {
			margin-bottom: 0.5rem;
			width: 80%;
		}

		& input {
			padding-left: 0.5rem;
		}

		& .fetch-error {
			height: 1.5rem;
			font-size: large;
			margin: 0.5rem 0;
		}

		& .editButtons {
			display: flex;
			justify-content: space-evenly;
			width: 50%;
			margin-top: 1rem;

			& button {
				height: 1.5rem;
			}
		}
	}
}
