@import './src/assets/scss/variables';

.AssociationDetail {
	display: grid;
	grid-template-columns: 29% 38% 10% 20% 3%;
	justify-items: center;
	align-items: center;
	font-size: larger;
	padding: 0.5rem 0;
	border: 1px solid $text-color;
	height: 3rem;
	position: relative;
	color: #000;
}

.associationsList-title {
	font-size: 1.6rem;
	font-weight: bold;
	height: 4rem;
	background-color: $sheet-title;
	color: #ffffff;
}

.edit-association-modals {
	& .modal-content {
		& .modal-header {
			justify-content: center;
		}

		& .modal-body {
			& div.error_message {
				text-align: center;
				color: $alert1;
				font-size: x-large;
			}

			& form {
				& fieldset {
					border-bottom: 1px solid $background-color;
					padding: 1rem 0;
				}

				& .edit-association {
					display: grid;
					grid-template-columns: 50% 50%;

					& div.mb-3 {
						width: 60%;
						justify-self: center;
					}
				}

				& .edit-user-buttons {
					display: flex;
					align-items: center;
					justify-content: center;
					border-bottom: none;

					& button {
						padding: 0 1rem;
						height: 3.5rem;
						margin: 1rem 0.5rem;
					}

					& .cancel {
						background-color: $button-cancel;
					}

					& .cancel:hover {
						background-color: $button-cancel-hover;
					}
				}
			}
		}
	}
}

.inactive {
	color: $text-color;
	font-style: italic;
}

@media screen and (max-width: $screen-xxl-min) {
	.AssociationDetail {
		font-size: medium;
	}

	.associationsList-title {
		font-size: larger;
		height: 3rem;
	}
}

@media screen and (max-width: $screen-xl-min) {
	.AssociationDetail {
		font-size: small;
	}

	.associationsList-title {
		font-size: medium;
	}
}

@media screen and (max-width: $screen-lg2-min) {
	.AssociationDetail {
		font-size: xx-small;
	}

	.associationsList-title {
		font-size: xx-small;
	}
}
