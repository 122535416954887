@import './src/assets/scss/variables';

.RecieverList {
	height: fit-content;

	& table {
		color: $text-color;
		width: 100%;
		background-color: #fff;

		display: flex;
		flex-direction: column;
		overflow: auto;
		max-height: 75vh;
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.RecieverList {
		& table {
			color: $text-color;
			width: 100%;
			font-size: x-small;
		}
	}
}

@media screen and (max-height: $screen-xl-min) {
	.RecieverList {
	}
}

@media screen and (max-height: $screen-md-min) {
	.RecieverList {
		& table {
			max-height: 65vh;
		}
	}
}
