@import './../../../../../assets/scss/variables';

.FilesTab {
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	padding: 0 2rem;

	& table {
		height: 70vh;
		& thead {
			background-color: $table-head;
			padding: 0.5rem;
			color: #ffffff;

			& tr {
				padding: 0.5rem;
			}
		}

		& tbody {
			display: block;
			max-height: 77vh;
			overflow-y: auto;
		}

		& tr {
			display: grid;
			grid-template-columns: 10% 20% 15% 25% 25%;
			padding: 0 0.5rem;
			margin-top: 0.3rem;

			& th {
				display: flex;
				justify-content: start;
				align-items: center;

				& button {
					color: $text-color;
					border: 1px solid $button-color;
					background-color: rgba($modal-background, 0.4);
					display: flex;
					justify-content: start;
					align-items: center;
					padding: 0 0.5rem;
				}
			}
		}
	}
}

@media screen and (max-height: 1440px) {
	.FilesTab {
		& table {
			height: 71vh;

			& tbody {
				max-height: 68vh;
			}
		}
	}
}

@media screen and (max-height: 1200px) {
	.FilesTab {
		& table {
			height: 65vh;

			& tbody {
				max-height: 61vh;
			}

			& tr {
				grid-template-columns: 15% 30% 15% 40%;
			}
		}
	}
}

@media screen and (max-height: 900px) {
	.FilesTab {
		& table {
			height: 57vh;

			& tbody {
				max-height: 53vh;
			}
		}
	}
}

@media screen and (max-height: 800px) {
	.FilesTab {
		& table {
			height: 57vh;

			& tbody {
				max-height: 52vh;
			}
		}
	}
}
