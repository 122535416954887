@import './src/assets/scss/variables';

.WebSiteCard {
  & .web_sites {
    display: flex;
    flex-direction: row;
    padding: 1rem;

    & label {
      margin-bottom: 1rem;
    }

    & svg {
      margin-left: 1rem;
    }

    & ul, select {
      margin-left: 10rem;
    }

    & select {
      width: 200px;
      min-height: 5rem;
    }

    & ul {
      & li {
        margin: .5rem 0;

        display: flex;

        & input {
          width: 20px;
        }

        & label {
          margin: auto 0 auto 1rem;
        }
      }
    }
  }
}