@import '../../../../../assets/scss/variables';

.WebSiteCard {
	width: 100%;
	display: flex;
	gap: 1rem;

	& form {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 1rem;

		& fieldset {
			width: 90%;
			display: flex;
			gap: 1rem;

			& .WebSiteCard__name {
				width: 30%;
			}

			& .WebSiteCard__url {
				flex-grow: 1;
			}
		}

		& svg {
			cursor: pointer;
			color: $button-danger;
		}
	}
}

.small_button {
	font-size: small;
	gap: 1rem;

	& button {
		height: 2rem;
		padding: 0 0.5rem !important;
	}
}
