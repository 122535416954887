@import '../../../../../assets/scss/variables';

.WebSite {
	display: flex;
	flex-direction: column;
	padding: 1rem 2rem;
	justify-content: start;
	align-items: center;
	color: #78777d;
	row-gap: 1rem;

	& h3 {
		font-size: xx-large;
		margin-bottom: 3rem;
		justify-self: start;
	}

	& .WebSite__list {
		display: flex;
		flex-direction: column;
		color: #78777d;
		width: 100%;
		gap: 1rem;

		& h3 {
			font-size: xx-large;
			margin-bottom: 3rem;
		}

		& .form-advertizer {
			margin-bottom: 2rem;
			font-size: larger;
			font-weight: bold;
		}

		& label,
		input,
		.buttonsWidth {
			//margin-bottom: 1rem;
			width: 50%;
		}

		& input {
			padding-left: 0.5rem;
		}

		& .buttonsWidth {
			display: flex;
			justify-content: space-evenly;
			width: 50%;
			margin-top: 2rem;
		}
	}

	& .switch-button {
		display: flex;
		justify-items: center;
		align-items: center;
		background: $modal-background;
		color: $text-color;
	}
}
