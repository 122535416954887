@import './reset';
@import './variables';

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

html {
	font-family: 'Roboto', 'Open Sans', sans-serif;
	color: $text-color;
}

body {
  background-color: $background-color;
  font-family: 'Roboto', 'Open Sans', sans-serif;
}
// hide scrollbar for chrome
body::-webkit-scrollbar {
}

html,
body {
	margin: 0;
	height: 100%;
	overflow: hidden;
}

#root {
	height: 100vh;
}

a {
	color: $button-color;
	text-decoration: underline;
}

a:hover {
	color: $button-hover;
	text-decoration: underline;
}

input,
select,
textarea,
button {
	border: none;
	border-radius: 8px;
	height: 2rem;
}

h1 {
	font-size: xx-large;
	text-align: center;
	margin-bottom: 2rem;
}

h2 {
	color: $Page-title;
	font-size: xx-large;
	font-weight: bolder;
}

button {
	padding: 0.85rem;
	font-size: larger;
	background-color: $button-color;
	color: #fff;
}

button:hover {
	background-color: $button-hover;
}

.return-button {
	color: $return-button;
	text-decoration: none;
	font-size: large;
	font-weight: bolder;
}

.return-button:hover {
	color: $return-button-hover;
	text-decoration: none;
}

.modal-content {
	background-color: $modal-background;
}

div.test.tooltipCustom {
	width: fit-content;
}
