@import '../../../../assets/scss/variables';

.AdminTab {

  & .product-tab-content {
    display: grid;
    grid-template-columns: 49% 49% 2%;

  }

  //tabs
  & div.tab-content {
    padding: 0.5rem 0;
    background-color: $background-color;
    border-radius: 0 8px 8px 8px;
    height: 73vh;
    grid-area: 2/1/2/3;
    overflow: hidden;

    & .tab-pane {
      margin: 1rem;

      & .PageSubventions,
      .ProjectsList {
        overflow-x: auto;
        overflow-y: auto;
        padding: 0;
        max-height: 60vh;
      }
    }
  }

  & .nav-tabs {
    grid-area: 1/1/1/1;

    & li > button {
      font-size: larger;
    }
  }

  & .nav-tabs .nav-link {
    border-radius: 8px 8px 0 0;
  }

  & button.nav-link {
    font-size: x-large;
    color: $background-color;
    font-weight: bolder;
    background-color: $text-color;
    height: 3rem;
  }

  & button.nav-link.active {
    font-weight: bolder;
    color: $sheet-title;
    background-color: $background-color;
  }

  & svg {
    cursor: pointer;
  }
}

@media screen and (max-height: $screen-xl-min){
  .AdminTab {

    //tabs
    & div.tab-content {
      height: 67vh;
      grid-area: 2/1/2/3;
      overflow: hidden;
    }

  }

}



@media screen and (max-height: $screen-lg-min){
  .AdminTab {

    //tabs
    & div.tab-content {
      height: 60vh;
    }

  }

}