@import '../../../../../assets/scss/variables';

.WebsitePage {
	display: grid;
	grid-template-columns: 48% 48% 4%;
	max-height: 100%;

	& .funderList {
		grid-area: 1/1/1/1;
		display: flex;
		flex-direction: column;
		max-height: 70vh;
		overflow: auto;
		background-color: #fff;
		border-radius: 10px;
		padding: 0.5rem;
		gap: 0.3rem;

		& .product-item {
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			cursor: pointer;

			& .product-item-code,
			.product-item-name {
				margin-right: 1rem;
			}

			& .product-item-name {
				margin-inline-end: auto;
			}
		}
	}

	& .WebSite {
		grid-area: 1/2/1/2;
	}

	& .buttons {
		grid-area: 1/3/1/3 !important;
		width: 50px !important;
		justify-self: center !important;
		align-self: start !important;
		gap: 0.2rem;
	}
}

@media screen and (max-height: $screen-xl-min) {
	.FunderPage {
		max-height: 63vh;
	}
}

@media screen and (max-width: $screen-4xl-min) {
}

@media screen and (max-width: $screen-xxl-min) {
}

@media screen and (max-height: $screen-lg-min) {
	.FunderPage {
		max-height: 54vh;
	}
}
