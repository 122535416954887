$text-color: #78777D;
$button-color: #47C8F4;
$background-color: #D0D0D7;
$button-hover: #C26CBC;
$button-danger: #C26CBC;
$button-danger-hover: #776491;
$button-cancel: #78777D;
$button-cancel-hover: #776491;
$link-return-hover: #776491;
$link-loggout-hover: #037172;
$Page-title: #037172;
$sheet-title: #03A2A3;
$dashboard-background: rgba(195, 255, 251, .8);
$return-button: #78777D;
$return-button-hover: #C26CBC;
$detail-text-color: #037172;
$detail-text-color-grey: #D0D0D7;
$modal-background: #C3FFFB;
$table-head: #03A2A3;

$alert1: #C26CBC;
$alert2: #776491;

$back-office-bg: #78777D;


// Small tablets and large smartphones (landscape view)
$screen-sm-min: 536px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// old desktops
$screen-lg2-min: 1024px;

// Large tablets and desktops
$screen-xl-min: 1280px;

// 2K and higher screens
$screen-xxl-min: 1800px;

// 2K and higher screens
$screen-4xl-min: 2440px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin flex($align, $justify, $flex-flow) {
  display: flex;
  flex-flow: $flex-flow;
  align-items: $align;
  justify-content: $justify;
}
