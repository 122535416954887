@import './src/assets/scss/variables';

.ButtonLoading {
	height: 3em;
}

.ButtonLoading:disabled {
	background: #78777d;
	cursor: not-allowed;
}
