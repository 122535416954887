@import '../../../../assets/scss/variables';

.AccountList {
    max-height: 70vh;
    overflow: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 0.5rem;

    & .product-item {
        display: flex;
        justify-content: space-between;
        padding: .3rem;
        cursor: pointer;


        & .product-item-code, .product-item-name {
            margin-right: 1rem;
        }

        & .product-item-name {
            margin-inline-end: auto;
        }
    }
}

@media screen and (max-height: $screen-xl-min){
    .AccountList {
        max-height: 63vh;
    }
}

@media screen and (max-width: $screen-4xl-min){

}

@media screen and (max-width: $screen-xxl-min) {
}

@media screen and (max-height: $screen-lg-min){
    .AccountList {
        max-height: 54vh;
    }
}

