@import './src/assets/scss/variables';

.DuplicateRefModal {
}

.modal-duplicate {
	color: $text-color;
	max-width: 1000px;

	& .modal-content {
		background-color: #fff;
	}

	& .modal-body {
		font-size: 2.5rem;
		min-height: 15vh;
		text-align: center;
		display: flex;
		align-items: center;
	}

	& .modal-footer {
		min-height: 7vh;
		display: flex;
		justify-content: center;
		align-items: center;

		& button {
			transform: scale(1.5);
			padding: 0 2rem;
		}
	}
}

.modal_flex_title {
	width: 100%;
	display: flex;
	justify-content: space-around;

	& h2 {
		justify-self: center;
		font-size: 3rem;
		color: $text-color;
	}

	& img {
		width: 4rem;
	}

	& svg {
		width: 5rem;
		height: 5rem;
		color: $button-hover;
	}
}

.modal_centered_footer {
	width: 100%;
	display: flex;
	justify-content: space-around;

	& button {
		padding: 0 1rem;
	}
}
