@import './src/assets/scss/variables';

.BackOffice {
	display: grid;
	grid-template-columns: 50% 48% 30px;
	align-content: center;
	align-items: center;
	max-width: 100%;

	& .buttons {
		grid-area: 1/3/1/3;
	}

	& .nav-tabs {
		grid-area: 1/1/1/1;
		border-bottom: none;

		& .nav-item {
			& button {
				border-radius: 8px 8px 0 0;
			}
		}

		& .nav-link {
			height: fit-content;
			color: $button-color;
			font-weight: bold;
			font-size: larger;
		}

		& .active {
			background-color: $background-color;
			border-radius: 8px 8px 0 0;
			color: $sheet-title;
		}
	}

	& .tab-content {
		grid-area: 2/1/2/4;
		height: 85vh;
		background-color: $background-color;
		border-radius: 0 8px 8px 8px;
		padding: 2rem 2rem;
	}

	& .searchBar {
		display: grid;
		grid-template-columns: 200px 80%;

		& .loading {
			color: $button-color;
		}
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.BackOffice {
		display: grid;

		& .nav-tabs {
			width: 100%;

			& .active {
				width: fit-content;
			}
		}

		& .searchBar {
			grid-template-columns: 50px 80%;

			& .loading {
				color: $button-color;
			}
		}
	}
}

@media screen and (max-width: $screen-xl-min) {
	.BackOffice {
		& .nav-tabs {
			& .nav-link {
				font-size: medium;
			}
		}

		& .tab-content {
			padding: 2rem 1rem;
		}
	}
}

@media screen and (max-width: $screen-lg-min) {
	.BackOffice {
		grid-template-columns: 60% 38% 30px;

		& .nav-tabs {
			& .nav-link {
				font-size: medium;
			}
		}

		& .tab-content {
			padding: 1rem;
		}
	}
}

@media screen and (max-width: $screen-lg2-min) {
	.BackOffice {
		grid-template-columns: 60% 38% 30px;
		max-width: 98%;

		& .nav-tabs {
			& .nav-link {
				font-size: xx-small;
			}
		}

		& .tab-content {
			padding: 1rem;
		}
	}
}

@media screen and (max-height: $screen-xl-min) {
	.BackOffice {
		& .tab-content {
			height: 80vh;
		}
	}
}

@media screen and (max-height: $screen-md-min) {
	.BackOffice {
		& .tab-content {
			height: 70vh;
		}
	}
}
