@import '../../assets/scss/variables';

.UserDetail {
	display: grid;
	grid-template-columns: 11% 15% 15% 20% 14% 8% 14% 3%;
	justify-items: center;
	align-items: center;
	font-size: larger;
	padding: 0.5rem 0;
	border: 1px solid $text-color;
	height: 3rem;
	position: relative;
	color: #000;

	& .usersList-dl {
		z-index: 1;
	}
}

.UserDetail-admin {
	grid-template-columns: 17% 17% 20% 20% 18% 8%;
}
// 17

.usersList-title {
	font-size: 1.6rem;
	font-weight: bold;
	height: 4rem;
	background-color: $sheet-title;
	color: #ffffff;
}

.inactive {
	color: $text-color;
	font-style: italic;
}

.edit-user {
	& .modal-content {
		& .modal-header {
			justify-content: center;
		}

		& .modal-body {
			& form {
				display: grid;
				grid-template-columns: 50% 50%;
				justify-content: center;

				& fieldset {
					& input,
					select {
						height: fit-content;
						min-height: 2.1rem;
					}
				}

				& fieldset.edit-user-infos,
				fieldset.edit-user-group,
				fieldset.edit-user-rights,
				fieldset.edit-user-projects {
					height: -webkit-fill-available;
					width: 100%;
					background-color: $modal-background;
					padding: 1rem;
					margin: 2rem 0 1rem 0;

					& div.mb-3 {
						width: 60%;
						margin: 1rem auto;
					}

					& div.switchs {
						width: 60%;
						margin: 1rem auto;
						display: flex;
						justify-content: space-between;
						align-items: center;
					}
				}

				& fieldset.lined {
					height: 0.5rem;
					border-bottom: 1px solid $background-color;
					grid-area: 2/1/2/3;
				}

				& fieldset.edit-user-group,
				fieldset.edit-user-projects {
					border-left: 1px solid $background-color;
				}

				& fieldset > h3 {
					font-size: large;
					text-align: center;
					font-weight: bold;
					color: $detail-text-color;
					margin-bottom: 2rem;
				}

				& fieldset.edit-user-group,
				fieldset.edit-user-status {
				}

				& fieldset.edit-user-rights,
				fieldset.edit-user-status {
				}

				& fieldset.new-assos {
					grid-column: 1/3;
					width: 50%;
					justify-self: center;
				}

				& .edit-user-infos,
				.edit-user-status {
					& .mb-3 {
						display: grid;
						grid-template-columns: 95% 5%;

						& label,
						select,
						input {
							grid-column: 1;
						}

						& svg {
							width: 0.5rem;
							justify-self: center;
							color: red;
						}
					}
				}

				& .edit-user-group {
				}

				& .edit-user-rights {
				}

				& fieldset.edit-user-contract {
					width: 100%;
					padding: 1rem;
					margin: 2rem 0 1rem 0;

					& div {
						width: 60%;
						margin: auto;
					}
				}

				& fieldset.edit-user-buttons {
					grid-area: 4/1/4/3;
					display: flex;
					justify-content: center;

					& button {
						margin: 1rem;
						height: 3rem;
					}

					& button:disabled {
						background-color: #78777d;
					}

					& button.cancel {
						background-color: #78777d;
					}

					& button.cancel:hover {
						background-color: #776491;
					}
				}
			}
		}
	}
}

.__react_component_tooltip.show {
	width: 30%;
}

@media screen and (max-width: $screen-xxl-min) {
	.UserDetail {
		font-size: medium;
	}

	.UserDetail.usersList-title {
		font-size: larger;
		height: 3rem;
	}
}

@media screen and (max-width: $screen-xl-min) {
	.UserDetail {
		font-size: small;
	}

	.UserDetail.usersList-title {
		font-size: medium;
	}
}

@media screen and (max-width: $screen-lg2-min) {
	.UserDetail {
		font-size: xx-small;
	}

	.UserDetail.usersList-title {
		font-size: xx-small;
	}
}
