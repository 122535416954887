@import './src/assets/scss/variables';

.SubventionDangerZone {
  & .sub-danger-zone {
    display: grid;
    grid-template-columns: 12% 20% 8% 20% 13% 20%;
    grid-template-rows: 50% 50%;
    align-items: center;
    justify-items: start;
    padding: 1rem 1rem 2rem 1rem;
    grid-row-gap: 10px;
    border: 3px solid pink;

    & input {
      width: 1.5rem;
    }

    & h4 {
      grid-area: 1/1/1/5;
      width: 100%;
      color: $button-danger;
    }

    & label {
      grid-area: 2/1/2/1;
    }

    & #closeSubvention {
      grid-area: 2/2/2/2;
      justify-self: flex-start;
    }
    /*
    & svg {
      grid-area: 2/2/2/2;
      justify-self: flex-start;
    }
*/
    & svg.disabled {
      color: #78777d;
    }

    & label.financial-infos-counted {
      grid-area: 2/3/2/3;
    }

    & input.financial-infos-counted {
      grid-area: 2/4/2/5;
    }

    & #delete-sub {
      grid-area: 2/6/3/6;
      height: 50px;
      background-color: $return-button-hover;
    }

    & #delete-sub:hover {
      background-color: $return-button;
    }

    & .delete-sub {
      grid-area: 2/5/2/5;
    }
  }
}

@media screen and (max-width: $screen-xxl-min) {
  .SubventionDangerZone {

    & .sub-danger-zone {
      grid-template-columns: 130px 120px 130px 120px 140px 80px;
    }
  }
}
