@import './src/assets/scss/variables';

.DeleteProjectModal {
  max-width: 600px;

  & .modal-content {
    & .modal-header {
      width: 100%;
      display: grid;
      grid-template-columns: 95% 5%;

      & .modal-title {
        justify-self: center;
      }
    }

    & .modal-body {
      align-self: center;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      & span.advertizing {
        margin: 2rem 0;
        font-weight: bolder;
        font-size: x-large;
      }

      & .buttons {
        margin: 2rem 0;

        & button {
          padding: 0 1rem;
          height: 40px;
          margin: 0 1rem;
        }

        & .cancel {
          background-color: $return-button;
        }
        & .cancel:hover {
          background-color: $return-button-hover;
        }

      }
        & .editButtons {
          grid-area: 5/1/5/4;
          justify-self: center;
          margin-bottom: 2rem;
          width: 100%;
        }
    }
  }
}