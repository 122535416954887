@import './assets/scss/variables';

.App {
  font-family: 'Roboto', 'Open Sans', sans-serif;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  overflow: hidden;
  max-height: 100vh;

  & .Main {
    width: 100%;
    //height: 80vh;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: $return-button;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background: $background-color;
  border-radius: 8px;
}

@media screen and (max-width: $screen-lg-min) {

}

@media screen and (min-height: $screen-xl-min) {


}