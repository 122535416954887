@import './src/assets/scss/variables';

.Presentation {
	padding-top: 8rem;
	overflow: auto;
	height: 100vh;

	@media screen and (max-height: 667px), screen and (max-width: 768px) {
		padding-top: 1rem;
	}

	& .logo {
		position: fixed;
		top: 0;
		left: 0;

		& img {
			width: 100px;
			height: 70px;
		}

		@media screen and (max-height: 667px), screen and (max-width: 768px) {
			position: inherit;
			text-align: center;
		}
	}

	& .prices-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 2rem;

		& .prices-title {
			font-size: 4rem;
			font-weight: 600;
			margin-bottom: 1rem;
		}

		& .prices-img {
			width: 60%;
			cursor: pointer;
		}
	}

	.presentation {
		&__header {
			display: flex;
			justify-content: flex-end;

			@media screen and (max-height: 667px), screen and (max-width: 768px) {
				flex-direction: column;
			}

			& button {
				padding: 0.75em;
				margin: 2em;
				height: auto;
				width: min-content;

				@media screen and (max-height: 667px), screen and (max-width: 768px) {
					width: 100%;
					margin: 1rem auto;
				}
			}

			& .contact_button {
				background-color: $button-danger;
				padding: 0.75em;
				height: auto;
			}

			& .connect_button {
				@media screen and (max-height: 667px), screen and (max-width: 768px) {
					display: none;
				}
			}
		}

		&__content {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			width: 60%;
			margin: auto;

			@media screen and (max-height: 1024px) {
				width: 90%;
			}

			@media screen and (max-height: 667px), screen and (max-width: 768px) {
				width: 100%;
			}

			&__video {
				position: relative;
				display: block;
				width: 55%;
				height: 0;
				margin: auto;
				padding: 0 0 42.7%;
				/* ratio 16/9eme */
				overflow: hidden;

				@media screen and (max-height: 667px), screen and (max-width: 768px) {
					width: 100%;
					padding: 30% 0 42.7%;
				}

				&__ytframe {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 80%;
					height: 80%;
					border: 0;

					@media screen and (max-height: 667px), screen and (max-width: 768px) {
						width: 95%;
						height: 95%;
					}
				}
			}

			&__text {
				width: 45%;
				display: flex;
				flex-direction: column;

				@media screen and (max-height: 667px), screen and (max-width: 768px) {
					width: 85%;
				}

				& p {
					display: block;
					width: 100%;
					text-align: justify;
					margin-top: 2em;
					margin-bottom: 2em;

					& ul {
						& li {
							list-style-type: circle;
							margin-bottom: 0.5rem;
						}
					}
				}
			}
		}
	}

	.footer {
		//position: fixed;
		//bottom: 0;
		//left: 50%;
		//transform: translate(-50%, 0);
		padding: 2rem;
		font-size: larger;
		font-weight: bold;
		display: flex;
		justify-content: center;

		& a {
			margin-left: 0.5rem;
		}

		@media screen and (max-height: 667px), screen and (max-width: 768px) {
			position: inherit;
			transform: none;
			text-align: center;
		}
	}
}
