@import '../../../../../assets/scss/variables';

.FunderCard {
	cursor: pointer;
	display: flex;
	gap: 1rem;
}

.FunderCard__selected {
	font-weight: bold;
	margin: 0.3rem 0;
}
