@import '/src/assets/scss/variables';

.SearchBar {
	font-family: 'Roboto', 'Open Sans', sans-serif;
	width: 60%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	align-items: center;

	& input.search-input {
		width: 20rem;
		margin-right: 3rem;
	}

	& input {
		padding-left: 1rem;
	}

	& .react-datepicker-wrapper {
		width: 11rem;
	}

	& .react-datepicker__input-container {
		width: 90%;

		& input {
			width: 8rem;
		}
	}

	& .archive-filter {
		display: flex;
		align-items: center;
		color: #78777d;

		& input {
			margin-left: 1rem;
			margin-right: 1rem;
			width: 25px;
		}

		& label {
			width: max-content;
		}
	}

	& svg {
		cursor: pointer;
	}

	& .react-datepicker__close-icon {
	}

	& .spinner-border {
		width: 25px;
		height: 25px;
		margin-left: 2em;
		color: $sheet-title;
	}

	& .spinner-text {
		margin-left: 0.5em;
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.SearchBar {
		width: 100%;

		& input.search-input {
			margin-right: 1em;
		}

		& .react-datepicker-wrapper {
			width: 9em;
		}

		& svg {
			width: 25px;
			margin-left: 2em;
		}
	}
}

@media screen and (min-width: $screen-xxl-min) {
	.SearchBar {
		& .react-datepicker__input-container {
			width: 75%;
		}
	}
}
