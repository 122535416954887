@import "./src/assets/scss/variables";

.PageSubventions {
	font-family: "Roboto", "Open Sans";
	padding: 1rem;

	& .subventions-container {
		width: 99.9%;

		& .sub-list {
			background-color: #fff;

			& th {
				display: flex;
				align-items: center;
			}

			& .subvention-ref {
				grid-column: 1;
				display: flex;
				justify-content: space-between;
			}

			& .subvention-name {
				grid-column: 2;
			}

			& .subvention-funder {
				grid-column: 3;
			}

			& .subvention-start_date {
				grid-column: 4;
				text-align: center;
			}

			& .subvention-end_date {
				grid-column: 5;
				text-align: center;
			}

			& .subvention-status {
				grid-column: 6;
			}

			& .subvention-versement {
				grid-column: 7;
			}

			& .subvention-etat {
				grid-column: 8;
			}

			& .subvention-amount_asked {
				grid-column: 9;
				text-align: end;
				padding-right: 0.5rem;
			}

			& .subvention-amount_contrated {
				grid-column: 10;
				text-align: end;
				padding-right: 0.5rem;
			}

			& .subvention-total_recieved {
				grid-column: 11;
				text-align: end;
				padding-right: 0.5rem;
			}

			& .subvention-remains {
				grid-column: 12;
				text-align: end;
				padding-right: 0.5rem;
			}

			& .subvention-remains-item,
			.subvention-amount_contracted-item {
				color: $Page-title;
				//border: solid 1px $background-color;
				border: 1px solid darkgray;
			}
		}
	}

	& a {
		text-decoration: none;
	}

	& .subventionList {
		margin: 1rem;
		padding-left: 0;
	}

	& .subventionList-title {
		height: 3rem;
		background: $sheet-title;
		color: #fff;
		align-items: center;
		justify-items: center;
		word-wrap: break-word;
		font-size: large;

		svg {
			cursor: pointer;
		}

		& .subvention-item-content {
			text-align: center;
		}
	}

	& .sub-list > .subvention-item-content {
		border: solid 1px $background-color;
		padding: 0.2rem 0.5rem;
	}

	& .subvention-item {
		display: grid;
		grid-template-columns: 7.7% 11% 10% 7% 7% 9.3% 8% 6% 8% 10% 8% 8%;

		//margin-bottom: 0.3rem;

		& .sub-detail-link {
			text-decoration: none;
		}

		& .title {
			width: 100%;
			height: 100%;
			display: grid;
			grid-template-columns: 85% 15%;
			align-content: center;
			justify-content: center;
			align-items: center;
			justify-items: center;

			& svg {
			}
		}
	}

	& .closed {
		color: #999;
		font-style: italic;
	}
}

@media screen and (max-width: $screen-xxl-min) {
	.PageSubventions {
		& .subventionList {
		}

		& thead > .subvention-item {
			font-size: initial;
		}

		& tbody > .sub-detail-link {
			font-size: x-small;
		}
	}
}

@media screen and (max-width: $screen-xl-min) {
	.PageSubventions {
		& .subventionList {
			margin: 1rem;
			padding-left: 0;

			& .sub-detail-link {
			}
		}

		& thead > .subvention-item {
			font-size: small;
		}

		& tbody > .sub-detail-link {
			font-size: xx-small;
		}
	}
}
