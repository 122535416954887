@import '../../assets/scss/variables';

.CollapsibleComponent {
	width: 100%;

	& .Collapsible__title {
		background: #ffffff;
		display: flex;
		justify-content: space-between;
		padding: 0.5rem 1rem;
		cursor: pointer;
	}
}
