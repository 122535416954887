@import '../../assets/scss/variables';

.TotalCard {
	min-width: 45%;

	& label {
		margin-right: 10px;
	}
}

.asked__item__quantity {
	margin-right: 50px;
}
