@import './../../../../src/assets/scss/variables';

.AddSubventionDocumentModal {
	& .modal-content {
		& .modal-header {
			display: grid;
			grid-template-columns: 90% 3%;
			justify-items: center;
		}

		& .modal-body {
			& form {
				display: flex;
				flex-direction: column;
				justify-items: center;
				align-items: center;

				& .Message {
					grid-area: 1/1/1/3;
					justify-self: center;
					color: red;
					font-size: large;
				}

				& .upload-infos {
					justify-self: center;
					margin-top: 2rem;
				}

				& .files-list {
					justify-self: center;
					width: 50%;
					row-gap: 0.5rem;
					margin-bottom: 2rem;

					& .file-item {
						display: flex;
						align-items: center;
						justify-content: center;

						& .file {
							background-color: #fff;
							border-radius: 7px;
							padding: 0.5rem 1rem;
							height: 82%;
							display: flex;
							flex-wrap: nowrap;
							align-content: center;
							align-items: center;
							justify-content: flex-end;
						}
					}
				}

				& .button {
					justify-self: center;
					margin-bottom: 3rem;

					& svg {
						margin-right: 1rem;
					}
				}

				& .advertising,
				.error-message {
					justify-self: center;
					color: $button-danger;
				}

				& .editButtons {
					justify-self: center;
					margin-bottom: 2rem;
					width: 50%;
				}
			}
			& ul {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				& li {
					& a {
						margin-left: 1rem;
					}
				}
			}
		}
	}
}
