@import './src/assets/scss/variables';

.CloseSubventionModal {}

  .close-subvention {
    max-width: 600px;

    & .modal-content {
      & .modal-header {
        width: 100%;
        display: grid;
        grid-template-columns: 95% 5%;

        & .modal-title {
          justify-self: center;
        }
      }

      & .modal-body {
        align-self: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        & span.advertizing {
          margin: 2rem 0;
          font-weight: bolder;
          font-size: x-large;
        }

        & .editButtons {
          width: 100%;
        }

      }
    }
  }
