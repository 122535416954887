@import './src/assets/scss/variables';

.PageLogin {
  margin-top: 5rem;

  & h1 {
    margin-bottom: 5rem;
  }

  & .container {
    width: 40%;
    max-width: 450px;
    margin: auto;
  }

  & .login-form {
    display: grid;
    grid-template-columns: 90% 10%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    & label {
      width: 100%;
      height: 1.5rem;
      grid-column: 1;
    }

    & input {
      width: 100%;
      height: 2rem;
      grid-column: 1;
      padding-left: .5rem;
    }

    & .input-eyes {
      transform: scale(1.8);
      grid-column: 2;
      align-self: center;
      justify-self: center;
    }

    & .ask-password-reset {
      margin-top: 0.2rem;
      justify-self: end;
    }

    & .message {
      margin-bottom: 2rem;
      font-size: x-large;
      font-weight: bolder;
      color: $button-danger;
    }
  }

  & .button-container {
    text-align: center;
    margin-top: 4rem;
    grid-column: 1 / span 2;
  }

  & .button {
    height: 3rem;
  }

  & .login-form > input:first-of-type {
    margin-bottom: 1.5rem;
  }
}