@import './src/assets/scss/variables';

.DocumentViewer {

}

.modal.show > div.DocumentViewer {
  width: 98%;
  max-width: 2000px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & .modal-body {
    //height: fit-content;
    height: 90vh;
    min-height: 80vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    overflow-y: scroll;
    justify-content: center;

    & .image-viewer {
      max-width: 90%;
      align-self: center;
    }
  }
}