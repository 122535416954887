@import '../../assets/scss/variables';

.ProjectDocuments {
	& .sub-expense-infos {
		display: grid;
		padding: 1rem;
		grid-template-columns: 110px 30px 87%;
		align-content: center;
		justify-content: start;
		align-items: center;
		justify-items: start;

		& h4 {
			grid-area: 1/1/1/3;
		}

		& svg {
			justify-self: flex-start;
			cursor: pointer;
		}

		& .document_button {
			display: flex;
			justify-content: flex-start;

			& svg.add-cross,
			svg.edit-pencil,
			svg.download-files {
				margin: 0 0.5rem;
			}
		}

		& ul {
			margin-top: 1rem;
			grid-area: 3/3/3/4;
			width: 100%;

			& .uploader {
				min-width: 25%;
			}

			& li > button {
				color: #78777d;
				background-color: #fff;
				justify-self: flex-start;
				padding: 0;
			}
		}

		& ul > li {
			margin: 0.5rem 0;
			display: flex;
			align-items: center;

			& div,
			button {
				margin-right: 2rem;
			}
		}

		& .toDel {
			font-style: italic;
			text-decoration: line-through;
		}

		& .delete-error {
			grid-area: 2/3/2/3;
		}
	}
}
