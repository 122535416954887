@import './src/assets/scss/variables';

.ProjectSubventionInfos {

  & .project-subvention-infos {
    & h3 {
      margin-bottom: 1rem;
    }

    & a {
      text-decoration: none;
    }

    & table {
      width: fit-content;
      margin-left: 2em;

      & thead {
        background-color: $table-head;
        color: #fff;
        height: 1.7em;
        display: flex;
        margin-bottom: 0.5em;
      }

      & .rows {
        display: grid;
        align-content: center;
        align-items: center;
        justify-items: center;
        width: fit-content;
      }

      & .subvention_rows {
        grid-template-columns: 10em 12em 12em 14em 8em;
      }

      & .self_financing_rows {
        grid-template-columns: 10em 12em;
      }
    }

    & .table-subventions {
      margin-bottom: 2em;
    }

    & .self_financing_title {
      display: flex;

      & h3 {
        margin-right: 1em;
      }
    }
  }

  & .editButtons {
    justify-self: center;
    margin:0 auto 2rem auto;
    width: 50%;
  }
}

@media screen and (max-width: $screen-xl-min) {

  .ProjectSubventionInfos {
    & .project-subvention-infos {
      & table {
        font-size: small;
        font-weight: 500;
      }
    }
  }
}